import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import useDeviceDetect from "../../../../hooks/use-device-detect"
import ViewAnimation from "../../../animation/view-animation"
import {
  StyledBlock,
  StyledContentWrapper,
  StyledDescription,
  StyledImageContainer,
  StyledImg,
  StyledTextContainer,
  StyledTitle
} from "./index.style"

const SpaceBlock = ({ blockId, dataName, title, description, edges }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <StyledBlock isMobile={isMobile}>
      <StyledImageContainer blockId={blockId}>
        {edges.map((image, index) => {
          const {
            node: {
              name,
              id,
              childImageSharp: { fluid }
            }
          } = image
          return (
            <ViewAnimation
              onClick={() =>
                typeof window !== "undefined" &&
                (
                  window.sessionStorage.setItem("imageId", id),
                  window.sessionStorage.setItem("dataName", dataName)
                )
              }
              className={name}
              key={id}
            >
              <AniLink
                style={{
                  height: "100%"
                }}
                fade
                target="_self"
                duration={1}
                to="/residences-lightbox"
              >
                <StyledImg fluid={fluid} alt={""} />
              </AniLink>
            </ViewAnimation>
          )
        })}
      </StyledImageContainer>
      <StyledContentWrapper isMobile={isMobile}>
        <StyledTextContainer isMobile={isMobile}>
          <StyledTitle>
            <ViewAnimation transitionDelay>{title}</ViewAnimation>
          </StyledTitle>
          <ViewAnimation transitionDelay>
            <StyledDescription>{description}</StyledDescription>
          </ViewAnimation>
        </StyledTextContainer>
      </StyledContentWrapper>
    </StyledBlock>
  )
}

export default SpaceBlock
