import styled from 'styled-components'

export const StyledLink = styled.span`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: ${({ isMobile }) => isMobile && '32px 0px'};
`

export const StyledTitle = styled.h2`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
  width: ${({ isMobile }) => isMobile ? '60%' : '100%'}
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 560px;
`

export const StyledContainer = styled.div`
  padding: ${({ isMobile }) => isMobile ? '32px 0px' : '20px 55px 60px 0'} ;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 270px;
`

export const StyledDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
`