import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import mergeQuery from "../../../../helpers/merge-query"
import { DefaultLayout } from "../../../layout"
import { StyledSection } from "./index.style"
import SpaceBlock from "./space-block"

const Space = () => {
  const textTemplate = {
    openSpace: {
      blockId: 1,
      dataName: "openSpace",
      title:
        "Open space and abundant natural light create a sanctuary of peace and tranquility",
      description:
        "The residences at Ruby offer the best in design, with every detail impeccably executed for comfort and convenience. Oversized windows and high ceilings up to 15’ allow natural light to warm the interiors with contemporary features, including Smart Home Keyless Entry, Nest thermometers, oak flooring, oversized closets, blackout and solar shades, and ENERGY STAR certified Bosch washers and dryers."
    },
    thoughtfulAttention: {
      blockId: 2,
      dataName: "thoughtfulAttention",
      title:
        "Thoughtful attention to even the smallest details, with condominium-grade fixtures and finishes",
      description:
        "The Kitchens at Ruby are finished to the highest standard with an integrated or stainless steel Bosch appliance package that blends seamlessly with custom cabinetry and satin chrome hardware. Quartz countertops and the full-height antique white herringbone tile backsplashes complete the simple and beautiful heart of the home."
    },
    cleanSpace: {
      blockId: 3,
      dataName: "cleanSpace",
      title: "Clean, elegant design for the best in style and function",
      description:
        "A clean, elegant aesthetic is prevalent throughout every residence at Ruby, and the bathrooms are no exception. Polished chrome fixtures and mirrored cabinets with abundant storage allow the space to become uniquely yours. The wall-hung Oak vanity paired with a custom shelf is warm and welcoming against the neutral herringbone flooring. Select residences offer walk-in showers with full-height herringbone tiled walls enveloped by seamless glass."
    }
  }

  const query = useStaticQuery(graphql`
    {
      openSpace: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "residences/open-space" }
        }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      thoughtfulAttention: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "residences/thoughtful-attention" }
        }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      cleanSpace: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "residences/clean-space" }
        }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const mergedObject = mergeQuery(query, textTemplate)

  const blocks = mergedObject.map(item => {
    const { blockId, dataName, title, description, edges } = item

    return (
      <SpaceBlock
        edges={edges}
        key={blockId}
        blockId={blockId}
        dataName={dataName}
        title={title}
        description={description}
      />
    )
  })

  return (
    <DefaultLayout>
      <StyledSection>{blocks}</StyledSection>
    </DefaultLayout>
  )
}

export default Space
