import styled from "styled-components"
import Img from 'gatsby-image'
import { mediaMax } from "../../../../helpers/media-queries"

const returnRowsHeight = (blockId) => {
  switch (blockId) {
    case 1: return '100vh 100vh 100vh 65vh 65vh 100vh 65vh 100vh 65vh'
    case 2: return '100vh 65vh'
    case 3: return '100vh 100vh'
    default: return '100vh'
  }
}

const returnMobileRowsHeight = (blockId) => {
  switch (blockId) {
    case 1: return '40vh 40vh 40vh 25vh 25vh 40vh 25vh 40vh 25vh'
    case 2: return '40vh 25vh'
    case 3: return '40vh 40vh'
    default: return '100vh'
  }
}

const returnTemplateAreas = (blockId) => {
  switch (blockId) {
    case 1: return ` "main main"
    "image2 image2"
    "image3 image3"
    "image4 image5"
    "image6 image7"
    "image8 image8"
    "image9 image10"
    "image11 image11"
    "image12 image13"`
    case 2: return ` "main main"
    "image2 image3"
    `
    case 3: return ` "main main"
    "image2 image2"`
    default: return 'main main'
  }
}

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 150px;
`

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column-reverse'};
  align-items: flex-start;
  gap: 20px;
  position: relative;
`

export const StyledImageContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 60px;
  grid-template-rows: ${({ blockId }) => returnRowsHeight(blockId)};
  grid-template-areas: ${({ blockId }) => returnTemplateAreas(blockId)};

  .image-1 {
    grid-area: main;
  }
  .image-2 {
    grid-area: image2;
  }
  .image-3 {
    grid-area: image3;
  }
  .image-4 {
    grid-area: image4;
  }
  .image-5 {
    grid-area: image5;
  }
  .image-6 {
    grid-area: image6;
  }
  .image-7 {
    grid-area: image7;
  }
  .image-8 {
    grid-area: image8;
  }
  .image-9 {
    grid-area: image9;
  }
  .image-10 {
    grid-area: image10;
  }
  .image-11 {
    grid-area: image11;
  }
  .image-12 {
    grid-area: image12;
  }
  .image-13 {
    grid-area: image13;
  }

  ${mediaMax('tabletLandscape')} {
    grid-template-rows: ${({ blockId }) => returnMobileRowsHeight(blockId)};
  }
`

export const StyledTitle = styled.h4`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
`

export const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: ${({ isMobile }) => !isMobile && '330px'};
  text-align: ${({ isMobile }) => isMobile && 'center'};
`

export const StyledContentWrapper = styled.div`
  position: ${({ isMobile }) => isMobile ? 'static' : 'sticky'};
  top: 0;
  margin: 0;
  padding: 60px 0px 0px;
`

export const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  max-height: 100vh;
  ${mediaMax('tabletLandscape')} {
    max-height: 50vh;
  }
`
