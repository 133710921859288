import React from "react"
import PageAnimation from "../components/animation/page-animation"
import PageDescription from "../components/page-description"
import GlobalStyle from "../helpers/global-styles"
import Footer from "../components/footer"
import Space from "../components/pages/residences/space"
import Location from "../components/pages/residences/location"
import Seo from "../components/seo"
import Layout from "../components/app-layout"
import SmoothScroll from "../helpers/smooth-scroll"
import useDeviceDetect from "../hooks/use-device-detect"
import Promo from "../components/pages/residences/promo"

const Residences = () => {
  const { isMobile } = useDeviceDetect()

  const description =
    "Conscientiously-crafted to cultivate well-being and inspire sensory delight, Ruby’s bright, spacious interiors are efficient, uplifting, and beautiful in every detail."

  return (
    <>
      <Layout pageName={"Residences"}>
        <SmoothScroll>
          <GlobalStyle />
          <Promo url={'https://vimeo.com/796391134/bf870eb65f'} isFullHeight={!isMobile} />
          <PageDescription text={description} />
          <Space />
          <Location />
          <Footer />
        </SmoothScroll>
      </Layout>
    </>
  )
}

export const Head = () => <Seo title="Residences" />

export default Residences
