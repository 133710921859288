import React from "react"
import Intro from "../../../intro"
import { DefaultLayout } from "../../../layout"
import ViewAnimation from "../../../animation/view-animation"
import { graphql, useStaticQuery } from "gatsby"
import CategoryLink from "../../../categories/category-link"
import {
  StyledContainer,
  StyledDescription,
  StyledLink,
  StyledTextContainer,
  StyledTitle
} from "./index.style"
import useDeviceDetect from "../../../../hooks/use-device-detect"

const Location = () => {
  const { isMobile } = useDeviceDetect()

  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "residences/intro/footer-intro.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section>
      <ViewAnimation>
        <Intro withoutScrollIcon query={query} isFullHeight={!isMobile} />
      </ViewAnimation>
      <DefaultLayout>
        <ViewAnimation>
          <StyledContainer isMobile={isMobile}>
            <StyledTextContainer>
              <StyledTitle isMobile={isMobile}>
                A Modern Jewel in a Timeless Location
              </StyledTitle>
              <StyledDescription>
                Designed by celebrated COOKFOX Architects, Ruby's elegant
                exterior of articularted masonry was inspired by the rich
                historic architectual fabric of the neigbhorhood. With Juliet
                railings and window opening that enhance city views, Ruby blends
                in harmony with its local context while standing out with its
                contemporary, sophisticated style.
              </StyledDescription>
            </StyledTextContainer>
            <StyledLink isMobile={isMobile}>
              <CategoryLink link={"/amenities"} title={"Amenities"} />
            </StyledLink>
          </StyledContainer>
        </ViewAnimation>
      </DefaultLayout>
    </section>
  )
}

export default Location
