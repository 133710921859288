import styled from "styled-components"
import { mediaMin, mediaMax } from "../../../../helpers/media-queries"
import Img from "gatsby-image"
import { calculateResponsiveSize } from "../../../../helpers/calculate-responsive-size"

export const VideoWrapper = styled.div`
  position: relative;
  height: calc(100vh - 50px);
  margin-top: 50px;
  ${mediaMax("tabletLandscape")} {
    height: calc(50vh - 80px);
    margin-top: 80px;
  }
  `

export const VideoContainer = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: 1;
  opacity: 1;
  transition: opacity 2s linear;
  cursor: pointer;

  background: url("residences-preview.jpg") no-repeat center;
  background-size: cover !important;

  iframe {
    width: 100vw !important;
    height: 60.25vw !important;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    }
 
  &.hide {
    opacity: 0;
    .video,
    .preview {
      z-index: -1;
    }
  }

  ${mediaMax("tabletLandscape")} {
    iframe {
        width: 100vw !important;
        min-height: 60vh;
        min-width: 80vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  }

  video {
    object-fit: cover;
    display: flex;
    justify-self: center;
    width: 100vw;
    height: 100vh;
  }

`

export const PromoContentInner = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 2s linear;
  text-align: center;
  cursor: default;
  &.show {
    opacity: 1;
  }
`

export const PromoLogoWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 28vh;
  margin-bottom: 6vh;

  ${mediaMax("desktopSmall")} {
    height: 21.5vh;
    margin-bottom: 1vh;
  }
  ${mediaMax("tabletLandscape")} {
    height: 14.5vh;
  }
  ${mediaMax("phoneXL")} {
    height: 12.5vh;
  }

  .video-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;

    video {
    }
  }
`

export const PromoContentBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: var(--main-bg-color);
`

export const PromoContentWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.3s linear 2s;
  cursor: pointer;
  &.enable-click {
    /* pointer-events: none; */
  }
  .register-btn {
    margin-bottom: 50px;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 18px;
    padding: 5px 16px;
    border: 1px solid var(--main-color);
    background: transparent;
    height: 34px;
    box-sizing: border-box;
    line-height: 1.5;
    text-decoration: none;
    color: inherit;
    font-family: inherit;
    cursor: pointer;
    &:hover {
      background-color: var(--main-color);
      color: var(--main-bg-color);
    }
    svg {
      display: block;
      margin-left: 12px;
    }
    ${mediaMax("tablet")} {
      margin-bottom: 85px;
    }
  }
  ${mediaMax("desktopSm")} {
    justify-content: flex-end;
  }
  ${mediaMin("desktopXLarge")} {
    padding: ${calculateResponsiveSize(18)};
    .register-btn {
      margin-bottom: ${calculateResponsiveSize(28)};
      font-size: ${calculateResponsiveSize(15)};
      padding: ${calculateResponsiveSize(5)} ${calculateResponsiveSize(16)};
      height: ${calculateResponsiveSize(34)};
      svg {
        width: ${calculateResponsiveSize(11)};
        height: ${calculateResponsiveSize(13)};
        margin-left: ${calculateResponsiveSize(12)};
      }
    }
  }
`

export const PromoParagraph = styled.p`
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 175%;
  &:last-of-type {
    margin-bottom: 26px;
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(8)};
    font-size: ${calculateResponsiveSize(17)};
    &:last-of-type {
      margin-bottom: ${calculateResponsiveSize(26)};
    }
  }

  ${mediaMax("tablet")} {
    font-size: 12px;
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 20px;
    }
    br {
      display: none;
    }
  }
`
export const PromoParagraphItalic = styled(PromoParagraph)`
  font-style: italic;
  letter-spacing: 0.22em;
  text-transform: uppercase;
`

export const RubyLogoWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: all 2s linear;
  opacity: 1;
  height: 100%;
  z-index: 999;
  svg {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    color: var(--main-bg-color);
    margin-left: -6px;
    margin-top: -2px;
  }
  &.hide {
    opacity: 0;
  }
`
export const RubyMaskWrap = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;

  svg {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`

export const MuteBtn = styled.div`
  width: 33px;
  height: 33px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  cursor: pointer;
  ${mediaMin("desktopXLarge")} {
    width: ${calculateResponsiveSize(33)};
    height: ${calculateResponsiveSize(33)};
    position: absolute;
    bottom: ${calculateResponsiveSize(16)};
    right: ${calculateResponsiveSize(16)};
  }
`

export const AdressWrap = styled.div`
  width: 185px;
  margin-bottom: 26px;
  ${mediaMax("desktopSm")} {
    width: 110px;
    margin-top: 0;
    margin-bottom: 90px;
  }
  ${mediaMin("desktopXLarge")} {
    width: ${calculateResponsiveSize(185)};
    margin-bottom: ${calculateResponsiveSize(26)};
  }
  svg {
    width: 100%;
    height: auto;
  }
  @media (orientation: landscape) and (max-width: 915px) {
    margin-bottom: 10px;
  }
`

export const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  a:nth-child(2) {
    margin: 0 40px;
  }
  .housing {
    position: absolute;
    bottom: 15px;
    right: 26px;
  }
  ${mediaMax("phoneXL")} {
    flex-direction: column;
  }
  a {
    display: block;
    color: inherit;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    ${mediaMax("phoneXL")} {
      font-size: 10px;
    }
  }
  ${mediaMin("desktopXLarge")} {
    a {
      font-size: ${calculateResponsiveSize(12)};
      line-height: ${calculateResponsiveSize(20)};
    }
    a:nth-child(2) {
      margin: 0 ${calculateResponsiveSize(40)};
    }
    .housing {
      bottom: ${calculateResponsiveSize(15)};
      right: ${calculateResponsiveSize(26)};
    }
  }
`
export const StyledAnchor = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  ${mediaMax("phoneXL")} {
    font-size: 10px;
  }
`
export const ContentSwitcher = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  opacity: 1;
  /* pointer-events: none; */
  &:not(.mobile) {
    transition: opacity 0.1s linear 0.1s;
  }
  &.mobile {
    transition: opacity 1s linear 1s;
  }
  &.hide {
    opacity: 0;
    /* pointer-events: auto; */
  }
`


export const Image = styled(Img)`
  z-index: -1;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
`