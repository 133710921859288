import React from "react"
import ReactPlayer from "react-player"
import ScrollIcon from "../../../intro/icons/scroll-icon"
import { SrollIconWrapper, Text } from "../../../intro/index.style"
import { MouseWheel } from "../../amenities/promo/index.style"
import * as Styled from "./index.style"

const Promo = ({ url, isFullHeight }) => {
  const [playedSeconds, setPlayedSeconds] = React.useState(0)

  return (
    <>
      <Styled.VideoWrapper>
        <Styled.VideoContainer>
          <ReactPlayer
            config={{
              preload: true,
              vimeo: {
                playerOptions: {
                  background: true,
                  playsinline: true
                }
              }
            }}
            onProgress={e => setPlayedSeconds(e.playedSeconds)}
            width="100%"
            height="100%"
            url={url}
            playing={playedSeconds < 12}
          />
        </Styled.VideoContainer>
      </Styled.VideoWrapper>
      {!isFullHeight ? null : (
        <SrollIconWrapper>
          <ScrollIcon />
          <Text>Scroll</Text>
          <MouseWheel />
        </SrollIconWrapper>
      )}
    </>
  )
}

export default Promo
